import { loadStripe } from '@stripe/stripe-js'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_live_SAO5zmCNp1HfQxRkvfLU7YwS')
  }
  return stripePromise
}

export default getStripe
