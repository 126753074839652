import React, { useEffect, useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import KeyIcon from '@mui/icons-material/Key'

export default function Oauth({ location }) {
  const [href, setHref] = useState('')
  useEffect(() => {
    setHref(location.href)
  }, [location.search])
  return (
    <a
      href={`https://smarthome.candyhouse.co/?a=b&redirect_uri=${href}&client_id=partners&state=tse`}
      target='_blank'
    >
      <Tooltip title='Oauth devices'>
        <IconButton>
          <KeyIcon target='_blank' sx={{ color: 'primary.main' }} />
        </IconButton>
      </Tooltip>
    </a>
  )
}
