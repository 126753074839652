import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import { GlobalStateContext } from '../../context/GlobalContextProvider'
import LoadingPage from './LoadingPage'

function PrivateRoute({ children }) {
  const { gAuth } = useContext(GlobalStateContext)

  console.log('[PrivateRoute]', gAuth.loginState)
  if (gAuth.loginState === 'signIn' || gAuth.loginState === 'configured') {
    return children
  } else if (gAuth.loginState === 'tokenRefresh') {
    return <LoadingPage />
  } else {
    navigate('/login')
    return <LoadingPage />
  }
}

export default PrivateRoute
