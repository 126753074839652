import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import { Link } from 'gatsby'
export default function ChargeRecord({ charge }) {
  return (
    <Card>
      <CardHeader title={<Typography variant="h2">お支払い履歴</Typography>} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>お支払い日</TableCell>
              <TableCell>お支払い金額 </TableCell>
              <TableCell>お支払い状況</TableCell>
              <TableCell>クレジットカード</TableCell>
              <TableCell>領収書</TableCell>
            </TableRow>
          </TableHead>
          {charge.map((row) => (
            <TableBody key={row.id}>
              <TableRow>
                <TableCell>
                  {new Date(row.created * 1000).toLocaleDateString()}
                </TableCell>
                <TableCell>{row.amount}円</TableCell>
                <TableCell>
                  {row.refunds.total_count !== 0 ? 'refunded' : row.status}
                </TableCell>
                <TableCell>
                  {row.payment_method_details.card.brand}-
                  {row.payment_method_details.card.last4}
                </TableCell>
                <TableCell>
                  <Link to={row.receipt_url}>
                    <IconButton sx={{ padding: 'unset' }}>
                      <FindInPageOutlinedIcon
                        target="_blank"
                        sx={{ color: 'primary.main' }}
                      />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </CardContent>
    </Card>
  )
}
