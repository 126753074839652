import CryptoJS from 'crypto-js/core'
import { Buffer } from "buffer"

// cmd: 88, history: Buffer.from(gAuth.loginMail).toString('base64'), sign: generateRandomTag(secretKey),

const cmdCommand = (cmd= 88,historyTag,secret) => {
  const date = Math.floor(Date.now() / 1000)
  const dateDate = Buffer.allocUnsafe(4)
  dateDate.writeUInt32LE(date)
  const message = Buffer.from(dateDate.slice(1, 4))
  const msg = CryptoJS.enc.Hex.parse(message.toString('hex'))
  const key = CryptoJS.enc.Hex.parse(secret)
  const randomTag = CryptoJS.CMAC(key, msg)
  return {
    cmd: 88, history: Buffer.from(historyTag).toString('base64'), sign:  randomTag.toString(),
  }
}
export default cmdCommand
