import React, { useContext } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { API } from 'aws-amplify'
import { GlobalStateContext } from '../context/GlobalContextProvider'

export default function PersonalKeys({ keyList, setKeyList }) {
  const { gAuth } = useContext(GlobalStateContext)
  return (
    <Tooltip title='user devices'>
      <IconButton
        onClick={(e) => {
          API.get('Partners_Web_API', `/user_device`, {
            queryStringParameters: {
              sub: gAuth.loginSub,
            },
          })
            .then((res) => {
              setKeyList(keyList.concat(res))
              console.log('keyList', keyList)
            })
            .catch((err) => console.log(err))
        }}
      >
        <PersonIcon sx={{ color: 'primary.main' }} />
      </IconButton>
    </Tooltip>
  )
}
