import React, { useState, useContext } from 'react'
import Auth from '@aws-amplify/auth'
import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import PrivateRoute from './Auth/PrivateRoute'
import siteIcon from './../images/site-icon.png'

const Layout = ({ children }) => {
  const { gStripe } = useContext(GlobalStateContext)
  const headerHeight = '48px'
  const [anchorEl2, setAnchorEl2] = useState(null)

  return (
    <PrivateRoute>
      {typeof window !== 'undefined' && (
        <Grid
          container
          style={{ backgroundColor: '#EEEEEE', minHeight: '100%' }}
        >
          <AppBar position='fixed' sx={{ flexGrow: 1 }} elevation={0}>
            <Toolbar variant='dense'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  borderRadius: '50%',
                }}
              >
                <img src={siteIcon} width={30} height={30} alt='' />
              </Box>
              <>
                <Box sx={{ fontWeight: 'bold', display: 'flex', mr: 'auto' }}>
                  <Typography
                    variant='h4'
                    color='initial'
                    sx={{ fontWeight: 'bold', ml: '10px' }}
                  >
                    {typeof window !== 'undefined'
                      ? localStorage.getItem('currAccountName')
                      : gStripe.customer.name}
                  </Typography>
                </Box>
              </>
              <>
                <IconButton
                  sx={{ cursor: 'pointer' }}
                  onClick={(event) => {
                    setAnchorEl2(event.currentTarget)
                  }}
                >
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  id='basic-menu'
                  keepMounted
                  anchorEl={anchorEl2}
                  open={Boolean(anchorEl2)}
                  onClose={() => {
                    setAnchorEl2(null)
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      Auth.signOut()
                    }}
                  >
                    <LogoutIcon
                      sx={{
                        fontSize: '14px',
                        fill: 'rgb(204, 204, 204)',
                        mr: '10px',
                      }}
                    />
                    ログアウト
                  </MenuItem>
                </Menu>
              </>
            </Toolbar>
          </AppBar>
          <Grid
            className='child'
            item
            sx={{
              marginTop: headerHeight,
              marginLeft: '0px',
              width: '100%',
              height: '100%',
            }}
          >
            {children}
          </Grid>
        </Grid>
      )}
    </PrivateRoute>
  )
}

export default Layout
