import React from 'react'
import {Tooltip, IconButton} from '@mui/material'
import {Buffer} from 'buffer'
import {Decoder} from '@nuintun/qrcode'
import QrCodeIcon from '@mui/icons-material/QrCode'

const SesameKeyQrcode = ({setKey, setApiResponse}) => {
    const readQrcode = (imgUrl) => {
        new Decoder()
            .scan(URL.createObjectURL(imgUrl))
            .then((result) => {

                const urlParams = new URLSearchParams(result.data)
                const sk = urlParams.get('sk').replace(/ /g, '+')
                const data = Buffer.from(sk, 'base64')
                const lengths = [8, 4, 4, 4, 12]
                let parts = []
                let range = 0
                for (let i = 0; i < lengths.length; i++) {
                    parts.push(
                        Buffer.from(data.slice(83, 99))
                            .toString('hex')
                            .slice(range, range + lengths[i])
                            .toUpperCase()
                    )
                    range += lengths[i]
                }
                const secretKey = Buffer.from(data.slice(1, 17)).toString('hex')
                const uuidHyphened = parts.join('-')
                setKey({UUID: uuidHyphened, secretKey: secretKey})
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Tooltip title='デバイスのQRコードをアップロード'>
            <IconButton
                component='label'
                onClick={() => {
                    setApiResponse(undefined)
                }}
            >
                <QrCodeIcon target='_blank' sx={{color: 'primary.main'}}/>
                <input
                    type='file'
                    hidden
                    onChange={(event) => {
                        if (event.target.files[0]) {
                            readQrcode(event.target.files[0])
                        }
                    }}
                />
            </IconButton>
        </Tooltip>
    )
}

export default SesameKeyQrcode
