import React, { useContext, useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import getStripe from '../components/utils/stripe'
import CreditCardInfo from './creditCardInfo'
import { Box } from '@mui/material'
import { API } from 'aws-amplify'
import { GlobalStateContext } from '../context/GlobalContextProvider'
export default function AddCard() {
  const [clientSecret, setClientSecret] = useState('')
  const { gStripe } = useContext(GlobalStateContext)
  useEffect(() => {
    if (gStripe.customer.id) {
      getCardInfo(gStripe.customer.id)
    }
  }, [gStripe.customer.id])
  const getCardInfo = (customer) => {
    API.post('Partners_Web_API', `/charge_api/credit_card/intent`, {
      body: {
        customer,
      },
    })
      .then((res) => {
        // console.log('secret', res)
        setClientSecret(res)
      })
      .catch((err) => console.log(err))
  }
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#28aeb1',
    },
  }
  const options = {
    clientSecret,
    appearance,
  }
  return (
    <Box>
      {clientSecret && (
        <Elements options={options} stripe={getStripe()}>
          <CreditCardInfo />
        </Elements>
      )}
    </Box>
  )
}
