import React from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

const cardStyles = {
  width: '30vw',
  minWidth: '500px',
  alignSelf: 'center',
  boxShadow:
    ' 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07)',
  borderRadius: ' 7px',
  padding: '40px',
}
const buttonStyles = {
  background: ' #28aeb1',
  fontFamily: 'Arial, sans-serif',
  color: ' #ffffff',
  borderRadius: '4px',
  border: 0,
  padding: '12px 16px',
  fontSize: '16px',
  fontWeight: 600,
  cursor: 'pointer',
  display: 'block',
  transition: ' all 0.2s ease',
  boxShadow: '0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)',
  width: '100%',
}

export default function CreditCardInfo() {
  let indexPage = typeof window !== 'undefined' ? window.location.href : ''
  const stripe = useStripe()
  const elements = useElements()
  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: indexPage,
      },
    })
  }
  return (
    <div style={cardStyles}>
      <PaymentElement />
      <button
        style={buttonStyles}
        disabled={!stripe || !elements}
        onClick={() => {
          handleSubmit()
        }}
      >
        add card
      </button>
    </div>
  )
}
