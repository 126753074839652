import React, { useEffect, useState, useContext } from 'react'
import {
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardContent,
  Card,
  CardHeader,
  Collapse,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import { API } from 'aws-amplify'
import { Box } from '@mui/system'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import { styled } from '@mui/material/styles'
import AddCard from './addCard'
import Hider from './utils/Hider'

export default function CreditCard() {
  const [cardInfo, setCardInfo] = useState([])
  const { gStripe } = useContext(GlobalStateContext)
  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(45deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }))
  useEffect(() => {
    if (gStripe.customer.id) {
      // 拿到卡片資訊
      API.get('Partners_Web_API', `/charge_api/credit_card`, {
        queryStringParameters: { customer: gStripe.customer.id }, //輸入email
      })
        .then((res) => {
          setCardInfo(res)
        })
        .catch((err) => console.log(err))
    }
  }, [gStripe.customer.id])

  const changeDefaultCard = (id) => {
    API.post('Partners_Web_API', `/charge_api/payment_method`, {
      body: {
        customerId: gStripe.customer.id,
        defaultPaymentMethod: id,
      },
    })
      .then(console.log('succeed to change default cards'))
      .catch((err) => console.log(err))
  }

  return (
    <Card>
      <div style={{ display: 'flex' }}>
        <CardHeader
          title={<Typography variant='h2'>お支払い方法</Typography>}
        />
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          sx={{ marginLeft: '2px' }}
        >
          <AddIcon />
        </ExpandMore>
      </div>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <AddCard />
      </Collapse>
      <CardContent>
        <List disablePadding>
          {cardInfo.map((row, index) => (
            <ListItemButton
              key={row.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '300px',
              }}
              onClick={(event) => {
                event.preventDefault()
                gStripe.setCustomer({
                  ...gStripe.customer,
                  invoice_settings: { default_payment_method: row.id },
                })
                changeDefaultCard(row.id)
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ListItemText
                  sx={{
                    opacity:
                      row.id ===
                      gStripe.customer.invoice_settings.default_payment_method
                        ? '1'
                        : '.5',
                  }}
                >
                  {row.brand} - {row.last4}
                </ListItemText>
                <ListItemText
                  disableTypography
                  primary={
                    <Hider
                      show={
                        row.id ===
                        gStripe.customer.invoice_settings.default_payment_method
                      }
                    >
                      <Typography
                        style={{
                          color: '#FFFFFF',
                          backgroundColor: '#68C6C8',
                          fontSize: '12px',
                          borderRadius: '3px',
                          padding: '2px',
                          marginLeft: '5px',
                          // display:
                          //   row.id ===
                          //   gStripe.customer.invoice_settings
                          //     .default_payment_method
                          //     ? 'block'
                          //     : 'none',
                        }}
                      >
                        デフォルト
                      </Typography>
                    </Hider>
                  }
                />
              </Box>
              <Hider show={cardInfo.length > 1}>
                <ListItemIcon
                  // sx={{ display: cardInfo.length > 1 ? 'block' : 'none' }}
                  onClick={(event) => {
                    event.stopPropagation()
                    //刪掉卡片
                    let tmp = [...cardInfo]
                    tmp.splice(index, 1)
                    setCardInfo(tmp)
                    if (
                      row.id ===
                      gStripe.customer.invoice_settings.default_payment_method
                    ) {
                      gStripe.setCustomer({
                        ...gStripe.customer,
                        invoice_settings: { default_payment_method: tmp[0].id },
                      })
                      changeDefaultCard(tmp[0].id)
                    }
                    API.del('Partners_Web_API', `/charge_api/credit_card`, {
                      body: { paymentId: row.id },
                    })
                      .then(() => console.log('success'))
                      .catch((err) => console.log(err))
                  }}
                >
                  <DeleteOutlineIcon
                    sx={{
                      color: 'black',
                      opacity:
                        row.id ===
                        gStripe.customer.invoice_settings.default_payment_method
                          ? '1'
                          : '.5',
                      ':hover': {
                        color: '#CC4A44',
                        opacity: 1,
                      },
                    }}
                  />
                </ListItemIcon>
              </Hider>
            </ListItemButton>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
