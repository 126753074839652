import React, { useContext, useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  Box,
  Card,
  CardContent,
  Grid,
  CardHeader,
  TextField,
} from '@mui/material'
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import Layout from '../layouts'
import Rules from '../components/rules'
import CreditCard from '../components/creditCard'
import ChargeRecord from '../components/chargeRecord'
import Hider from '../components/utils/Hider'
import SesameKeyQrcode from '../components/sesameKeyQrcode'
import cmdCommand from '../components/utils/cmdtag'
import Oauth from '../components/oauth'
import PersonalKeys from '../components/personalKeys'

const Intro = ({ location }) => {
  const { gAuth, gStripe } = useContext(GlobalStateContext)
  const [apiResponse, setApiResponse] = useState(undefined)
  const [invoiceComing, setInvoiceComing] = useState('')
  const [charge, setCharge] = useState([])
  const [sesameKey, setKey] = useState({ UUID: '', secretKey: '' })
  const [keyList, setKeyList] = useState([])

  useEffect(() => {
    if (gAuth.loginMail && typeof window !== 'undefined') {
      gStripe.getStripeInfo(gAuth.loginMail)
      localStorage.setItem('currLogin', gAuth.loginMail)
      localStorage.setItem('currAccountName', gAuth.loginMail)
    }
  }, [gAuth.loginMail, gStripe.customer.id])

  useEffect(() => {
    if (gStripe.subscription.id) {
      gStripe.getAPIRecords(gStripe.subscription.id)
    }
  }, [gStripe.subscription.id])

  useEffect(() => {
    if (location.search.split(/[=,&]/)[1]) {
      API.post('Partners_Web_API', `/oauth`, {
        body: location.search.split(/[=,&]/)[1],
      })
        .then((res) => {
          setKeyList(keyList.concat(res.Items))
        })
        .catch((err) => console.log(err))
    }
  }, [location.search])

  useEffect(() => {
    if (gStripe.customer.id) {
      getInvoice(gStripe.customer.id)
      getCharge(gStripe.customer.id)
    }
  }, [gStripe.customer.id])

  const getInvoice = (customer) => {
    API.get('Partners_Web_API', `/charge_api/invoice`, {
      queryStringParameters: { customer },
    })
      .then((res) => {
        setInvoiceComing(res)
      })
      .catch((err) => console.log(err))
  }

  const getCharge = (customerId) => {
    API.get('Partners_Web_API', `/charge_api/charge`, {
      queryStringParameters: { customerId },
    })
      .then((res) => {
        setCharge(res)
      })
      .catch((err) => console.log(err))
  }

  return (
    <Layout>
      <Grid sx={{ padding: 1 }} container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={<Typography variant='h2'>ご利用のapi key</Typography>}
            />
            <CardContent>
              <Typography sx={{ display: 'inline-block' }}>
                {gStripe.customer.metadata.apiKeyValue}
              </Typography>
              <Tooltip title='api keyの変更'>
                <IconButton
                  onClick={() => {
                    API.post('Partners_Web_API', `/charge_api/apikey`, {
                      body: { customerId: gStripe.customer.id },
                    })
                      .then((res) => {
                        gStripe.setCustomer({
                          ...gStripe.customer,
                          metadata: {
                            apiKeyValue: res.apiKeyValue,
                            apiKeyId: res.apiKeyId,
                          },
                        })
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  }}
                >
                  <ChangeCircleOutlinedIcon
                    color='primary'
                    sx={{ cursor: 'pointer' }}
                  />
                </IconButton>
              </Tooltip>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <TextField
                  id='outlined-basic'
                  label='Device UUID'
                  variant='outlined'
                  fullWidth
                  sx={{ marginBottom: '10px', flexGrow: '1' }}
                  onChange={(e) => {
                    setKey({ ...sesameKey, UUID: e.target.value })
                  }}
                  value={sesameKey.UUID}
                />
                <TextField
                  id='outlined-basic'
                  label='Device Secret Key'
                  value={sesameKey.secretKey}
                  fullWidth
                  onChange={(e) => {
                    setKey({ ...sesameKey, secretKey: e.target.value })
                  }}
                  variant='outlined'
                  sx={{
                    marginBottom: '10px',
                    marginLeft: '10px',
                    flexGrow: '1',
                  }}
                />
                <Box sx={{ flexShrink: '0' }}>
                  <Hider show={keyList.length > 0}>
                    {keyList
                      .filter((obj) => obj.deviceModel !== 'wm_2')
                      .map((item) => {
                        return (
                          <Box
                            sx={{
                              ml: '10px',
                              borderLeft: '1px solid #CCCCCC',
                              pl: '10px',
                            }}
                          >
                            <Button
                              key={item.deviceUUID}
                              variant='outlined'
                              sx={{ mr: '10px', mt: '10px', p: '3px' }}
                              onClick={() => {
                                setApiResponse(undefined)
                                setKey({
                                  UUID: item.deviceUUID,
                                  secretKey: item.secretKey,
                                })
                              }}
                            >
                              {item.deviceName}
                            </Button>
                            <br />
                          </Box>
                        )
                      })}
                  </Hider>
                </Box>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Button
                  sx={{ marginRight: '10px', color: '#FFFFFF' }}
                  disabled={!sesameKey.UUID}
                  variant='contained'
                  onClick={() => {
                    setApiResponse(undefined)
                    API.get('SesameSDKPublic', `/sesame2/${sesameKey.UUID}`, {
                      headers: {
                        'x-api-key': gStripe.customer.metadata.apiKeyValue,
                      },
                    })
                      .then((res) => {
                        console.log('api succeed', res)
                        setApiResponse(res)
                        gStripe.getAPIRecords(gStripe.subscription.id)
                        getInvoice(gStripe.customer.id)
                      })
                      .catch((err) => {
                        setApiResponse(err.message)
                      })
                      .finally(() => {})
                  }}
                >
                  ステータス取得を実行
                </Button>
                <Button
                  sx={{ marginRight: '10px', color: '#FFFFFF' }}
                  disabled={!sesameKey.UUID}
                  variant='contained'
                  onClick={() => {
                    setApiResponse(undefined)
                    API.get(
                      'SesameSDKPublic',
                      `/sesame2/${sesameKey.UUID}/history`,
                      {
                        headers: {
                          'x-api-key': gStripe.customer.metadata.apiKeyValue,
                        },
                        queryStringParameters: { page: 0, lg: 5 },
                      }
                    )
                      .then((res) => {
                        setApiResponse(res)
                        gStripe.getAPIRecords(gStripe.subscription.id)
                        getInvoice(gStripe.customer.id)
                      })
                      .catch((err) => {
                        setApiResponse(err.message)
                      })
                  }}
                >
                  履歴の取得を実行
                </Button>
                <Button
                  sx={{ marginRight: '10px', color: '#FFFFFF' }}
                  disabled={!sesameKey.UUID || !sesameKey.secretKey}
                  variant='contained'
                  onClick={() => {
                    setApiResponse(undefined)
                    API.post(
                      'SesameSDKPublic',
                      `/sesame2/${sesameKey.UUID}/cmd`,
                      {
                        headers: {
                          'x-api-key': gStripe.customer.metadata.apiKeyValue,
                        },
                        body: cmdCommand(
                          88,
                          gAuth.loginMail,
                          sesameKey.secretKey
                        ),
                      }
                    )
                      .then((res) => {
                        gStripe.getAPIRecords(
                          gStripe.customer.subscriptions.data[0].id
                        )
                        getInvoice(gStripe.customer.id)
                      })
                      .catch((err) => {
                        console.log(err)
                        setApiResponse(err.message)
                      })
                  }}
                >
                  デバイスを操作
                </Button>
                <SesameKeyQrcode
                  sesameKey={sesameKey}
                  setKey={setKey}
                  setApiResponse={setApiResponse}
                />
                <Oauth location={location} />
                <PersonalKeys setKeyList={setKeyList} keyList={keyList} />
              </Box>
              <Hider
                show={
                  !gStripe.customer.invoice_settings.default_payment_method &&
                  (gStripe.usageRecord ===
                    gStripe.subscription.plan.tiers[0].up_to ||
                    gStripe.usageRecord >
                      gStripe.subscription.plan.tiers[0].up_to)&&
                      gStripe.subscription.status!=="trialing"
                }
              >
                <Typography variant='h6' color='error'>
                  本日の無料回数の上限に到達しました、引き続きご利用の場合はクレジットカード情報をご登録ください。
                </Typography>
              </Hider>
              <Typography sx={{ wordBreak: 'break-all' }}>
                {JSON.stringify(apiResponse)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Rules />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Typography variant='h2'>以下の期間のご利用明細</Typography>
              }
            />
            <CardContent>
              {gStripe.subscription && (
                <Typography>
                  期間：
                  {new Date(
                    gStripe.subscription.current_period_start * 1000
                  ).toLocaleString()}
                  ～
                  {new Date(
                    gStripe.subscription.current_period_end * 1000
                  ).toLocaleString()}
                </Typography>
              )}
              <Typography>
                api keyを{gStripe.usageRecord}回使用しました
              </Typography>
              <Typography>合計金額：{invoiceComing}円</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CreditCard />
        </Grid>
        <Grid item xs={12}>
          <ChargeRecord charge={charge} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Intro
