import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardHeader,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
export default function Rules() {
  return (
    <Card>
      <CardHeader title={<Typography variant="h2">料金プラン</Typography>} />
      <CardContent>
        <Box>
          <Typography>
            <CheckIcon sx={{ transform: 'translateY(5px)' }} />
            1ヶ月間で30,000 回まで無料でご利用いただけます、
            30,001回以降ご利用の場合は有料となります。
          </Typography>
          <Typography>
            <CheckIcon sx={{ transform: 'translateY(5px)' }} />
            1ヶ月間の使用回数により料金が計算され、1ヶ月に1度お支払いが発生します。
          </Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>回数</TableCell>
              <TableCell>金額/月（税別）</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1~30,000回まで</TableCell>
              <TableCell>free</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30,001~100,000回まで</TableCell>
              <TableCell>2,000円</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>100,001~300,000回まで</TableCell>
              <TableCell>5,000円</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>300,001~1,000,000回まで</TableCell>
              <TableCell>10,000円</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>1,000,001~3,000,000回まで</TableCell>
              <TableCell>15,000円</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3,000,001回以降</TableCell>
              <TableCell>20,000円</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
